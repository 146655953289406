//main:./grayscale.scss

#mainNav {
    min-height      : 88px;
    top             : 30px;
    background-color: white;

    .navbar-toggler {
        font-size: 80%;
        padding  : 0.75rem;
        color    : $primary;
        border   : 1px solid $primary;

        &:focus {
            outline: none;
        }
    }

    .navbar-brand {
        color      : $black;
        font-weight: 700;
        padding    : 0.9rem 0;
    }

    .navbar-nav {
        .nav-item {
            &:hover {
                color           : fade(white, 80%);
                outline         : none;
                background-color: transparent;
            }

            &:active,
            &:focus {
                outline         : none;
                background-color: transparent;
            }
        }
    }
    @media (min-width: 992px) {
        padding-top     : 0;
        padding-bottom  : 0;
        border-bottom   : none;
        background-color: transparent;
        transition      : background-color 0.3s ease-in-out;

        .navbar-brand {
            padding: 0.5rem 0;
            color  : fade-out($white, .5);
        }

        .nav-link {
            transition: none;
            padding   : 2rem 1.5rem;
            color     : fade-out($white, .5);

            &:hover {
                color: fade-out($white, .25);
            }

            &:active {
                color: white;
            }
        }

        &.navbar-shrink {
            background-color: $white;

            .navbar-brand {
                color: $black;
            }

            .nav-link {
                color        : $black;
                padding      : 1.5rem 1.5rem 1.25rem;
                border-bottom: 0.25rem solid transparent;

                &:hover {
                    color: $primary;
                }

                &:active {
                    color: darken($primary, 15%);
                }

                &.active {
                    color        : $primary;
                    outline      : none;
                    border-bottom: 0.25rem solid $primary;
                }
            }
        }
    }
}

.topbar {
    background-color: white;
    line-height     : 2em;
}