//main:./grayscale.scss

.tools {
    flex   : 1;
    padding: 2em;
    color  : #fff;

    .color--tool {
        position: relative;

        div {
            display : flex;
            position: relative;
        }

        &--steps {
            padding: 0.5em 0;
        }

        .vertical-range {
            display    : flex;
            align-items: center;
            width      : 5em;
            height     : 5em;
            transform  : rotate(-90deg);
        }

        .steps {
            position : absolute;
            top      : 50%;
            left     : 4em;
            transform: translate(0, -50%);
            padding  : 0;
            margin   : 0;
        }

        button {
            margin    : 0;
            font-size : 1.25em;
            background: none;
            position  : absolute;
            left      : 6.15em;
            top       : 50%;
            transform : translate(-25%, -50%);
        }
    }
}

.colors {
    flex          : 2;
    display       : flex;
    flex-direction: column;
    font-size     : 0.85em;

    .color {
        flex       : 1;
        display    : flex;
        align-items: center;
        padding    : 0 1em;
        transition : 300ms linear;
    }
}

button {
    margin         : 0.5em 1em;
    cursor         : pointer;
    background     : none; //#333;
    color          : #fff;
    outline        : none;
    border         : none;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    border-radius  : 1em;
    width          : 1em;
    height         : 1em;
    line-height    : 0;
    font-size      : 1.65em;
}

input {
    margin       : 0;
    padding      : 0 0.5em;
    font-size    : inherit;
    font-family  : inherit;
    border       : none;
    width        : 7em;
    outline      : none;
    border-radius: 0 1em 1em 0;

    &[type=color] {
        padding      : 0;
        width        : 2em;
        height       : 2em;
        border-radius: 1em 0 0 1em;

        &::-moz-color-swatch {
            border       : none;
            border-radius: 1em;
            transform    : scale(.75);
        }

        &::-webkit-color-swatch {
            border       : none;
            border-radius: 1em;
        }

        &::-webkit-color-swatch-wrapper {
            padding      : 0.25em;
            border-radius: 1em;
        }
    }

    &[type=range] {
        appearance   : none;
        outline      : none;
        display      : inline-block;
        padding      : 0;
        margin       : 0;
        border       : 0;
        width        : 100%;
        height       : 0.25em;
        border-radius: 1em;
        cursor       : pointer;
        font-size    : inherit;
        background   : var(--fg);
        // MOZILLA
        &::-moz-range-track {
            appearance: none;
            background: var(--fg);
            outline   : none;
        }

        &::-moz-focus-outer {
            border: 0;
        }

        &::-moz-range-thumb {
            appearance   : none;
            width        : 1em;
            height       : 1em;
            border       : none;
            border-radius: 1em;
            box-shadow   : 0 0 0 0.3em var(--bg);
            background   : var(--fg);
            transform    : scale(1);
            transition   : transform 0.3s ease-out;
        }

        &::-moz-range-thumb:active,
        &::-moz-range-thumb:focus {
            appearance: none;
            transform : scale(.85);
        }
        // BLINK/WEBKIT
        &::-webkit-slider-thumb {
            appearance   : none;
            width        : 1em;
            height       : 1em;
            border       : none;
            border-radius: 1em;
            box-shadow   : 0 0 0 0.3em var(--bg);
            background   : var(--fg);
            transform    : scale(1);
            transition   : transform 0.3s ease-out;
        }

        &::-webkit-slider-thumb:active,
        &::-webkit-slider-thumb:focus {
            appearance: none;
            transform : scale(.85);
        }
    }
}

.glyph {
    width          : 1em;
    height         : 1em;
    stroke         : #fff;
    fill           : none;
    stroke-width   : 10;
    stroke-linecap : round;
    stroke-linejoin: round;

    .solid {
        fill        : #fff;
        stroke      : none;
        stroke-width: 0;
    }

    &.svg-x {
        stroke: #999;
    }
}

.color-box {
    display: none;
    display: none;

    .main_color_tool {
        flex            : 1;
        display         : flex;
        display         : none;
        display         : none;
        font-size       : calc(1em + 1vw);
        background-color: red;
        z-index         : 999999;
    }
}
// accommodate for Safari
// hack: detect for Safari-only feature (as of September 2018)
@supports (-webkit-backdrop-filter: blur(2em)) {
    input[type=color] {
        display: none;
    }

    input {
        border-radius: 1em;
        padding      : 0.25em 0.5em;
        width        : 9em;
    }
}