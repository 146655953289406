.about-section {
  padding-top: 10rem;
  background: linear-gradient(to bottom, $black 0%,#{fade-out($black, .1)} 75%,#{fade-out($black, .2)} 100%),url(https://wordpress-58039-1262310.cloudwaysapps.com/wp-content/themes/jtapaintings/img/demo-image-01.jpg);
  
  background-repeat: no-repeat;
  background-size: cover;
  h2{
    text-shadow: 0 0 5px #000;
  }
  p {
    margin-bottom: 5rem;
    font-size: 1.2em;
    color: rgba(255,255,255,.8);
    text-shadow: 0 0 5px #000;
    color: #fff;
  }
}
