.topbar {
    min-height: 30px;

    a {
        text-decoration: none;
    }
}

.black_ground {
    background-color: #00458c;
    color           : #fff;
}